<template>
  <div class="containerBox">
    <div
      v-for="(item, index) in dataList"
      :key="index"
      class="item"
      :class="{ itemEn: LOCALE == 'en' }"
    >
      <div class="left">
        <div class="job">
          <a
            class="name"
            :href="
              '/positionDetail?parameter=' +
              _encode({
                id: item.id,
              })
            "
            target="_blank"
          >
            {{ item.name | textFormat }}
          </a>
        </div>
        <div class="des">
          <span class="money" v-if="item.salary_lowest != 0"
            >{{ item.salary_lowest | textFormat }}K —
            {{ item.salary_highest | textFormat }}K</span
          >
          <span class="money" v-else>{{ $t("salaryNegotiable") }}</span>

          <span class="adress">{{
            item.city_name_en | priorFormat(item.city_name_zh, LOCALE)
          }}</span>
          <span class="margin_l_r8">|</span>
          <span>{{ item.level_require | levelRequireFormat(LOCALE) }}</span>
          <span class="date" v-if="LOCALE == 'zh'"
            >{{ $t("releasedIn") }}{{ item.created_time | secondFormat }}</span
          >
        </div>
        <div v-if="LOCALE == 'en'" class="date">
          {{ $t("releasedIn") }}{{ item.created_time | secondFormat }}
        </div>
      </div>
      <div class="show_type1">
        <div class="content">
          <div v-if="showType === 1" class="img_box">
            <img
              :src="item.logo"
              :alt="$t('siffa')"
              class="img"
              v-if="item.logo"
            />
            <img :src="defaultLogo" alt="默认图" class="img" v-else />
          </div>
          <div v-if="showType === 1">
            <div
              class="company"
              :title="item.name_en | priorFormat(item.name_zh, LOCALE)"
            >
              {{ item.name_en | priorFormat(item.name_zh, LOCALE) }}
            </div>
            <div class="company_des">
              <span>{{ item.industry | industryFormat(LOCALE) }}</span>
              <span class="margin_l_r8">|</span>
              <span>{{ item.scale | staffSizeFormat(LOCALE) }}</span>
            </div>
          </div>
        </div>
        <div class="right">
          <div>
            <submitResume
              :position_id="item.id"
              @successChange="successSubmitResume"
            ></submitResume>
            <orderTalk
              class="margin_t_4"
              :in_companyId="item.company_id"
              :in_userId="item.person_in_charge"
              :position_id="item.id"
              @successChange="successChange"
              invitationType="1"
            ></orderTalk>
          </div>
        </div>
      </div>
    </div>
    <positionDialog :id="id" ref="positionDialog"></positionDialog>
    <noDataImg v-if="dataList.length === 0"></noDataImg>
  </div>
</template>

<script>
import positionDialog from "~jzs/components/position/positionDialog";
import submitResume from "~jzs/components/button/submitResume";
import orderTalk from "~jzs/components/button/orderTalk";

export default {
  name: "positionList",
  metaInfo() {
    return {
      title: this.$t("position"),
    };
  },
  props: {
    // 数据列表
    dataList: {
      type: Array,
      default() {
        return [];
      },
    },
    // 展现方式
    // 1是在职位中，鼠标移动上去，显示投递简历，预约面试，  2 是在xxx中，直接显示投递简历，预约面试
    showType: {
      type: Number | String,
      default: 1,
    },
  },
  components: {
    submitResume,
    orderTalk,
    positionDialog,
  },
  data() {
    return {
      id: null,
    };
  },
  methods: {
    showPositionDIalog(item) {
      this.id = item.id;
      this.$refs.positionDialog.dialogVisible = true;
    },
    goDetail(item) {
      this.$router.push({
        path: "/positionDetail",
        query: {
          parameter: this._encode({
            id: item.id,
          }),
        },
      });
    },
    goCompany(item) {
      this.$router.push({
        path: "/companyList/booth",
        query: {
          parameter: this._encode({
            company_id: item.company_id,
            booth_id: item.booth_id,
          }),
        },
      });
    },
    // 预约成功
    successChange() {
      this.$emit("successChange");
    },
    // 投递成功
    successSubmitResume() {
      this.$emit("successSubmitResume");
    },
  },
};
</script>

<style scoped lang="less">
.containerBox {
  padding: 0;
  position: relative;
  min-height: 200px;
}

.item {
  width: 100%;
  padding: 16px 30px;
  background: #ffffff;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 77px;

  .left {
    .job {
      cursor: pointer;
      font-weight: 800;
      a {
        color: #1890ff;
      }
    }

    .des {
      margin-top: 4px;
      color: #666666;

      .money {
        font-weight: 400;
        color: #ff2b0d;
        margin-right: 16px;
      }
    }
    .date {
      color: #999999;
      font-size: 12px;
      // margin-left: 16px;
    }
  }

  .show_type1 {
    display: flex;
    align-items: center;
    width: 54%;

    .content {
      width: 350px;
      display: flex;

      .img_box {
        width: 45px;
        height: 45px;
        margin-right: 6px;
        border-radius: 2px;
        border: 1px solid #e9e9e9;
        display: flex;
        justify-content: center;
        align-items: center;

        .img {
          max-width: 39px;
          height: 39px;
        }
      }

      .company {
        margin-top: 4px;
        color: #333333;
        width: 200px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
      }

      .company_des {
        font-size: 12px;
        color: #999999;
        margin-top: 4px;
      }
    }

    .right {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .btn {
      padding: 4px 22px;
      background: #1890ff;
      border-radius: 11px;
      border: 1px solid #1890ff;
      display: flex;
      color: #ffffff;
      font-size: 12px;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .sendedBtn {
      padding: 4px 22px;
      background: #1890ff;
      border-radius: 11px;
      border: 1px solid #1890ff;
      display: flex;
      color: #ffffff;
      font-size: 12px;
      justify-content: center;
      align-items: center;
    }

    .sendBtn {
      background: #ffffff;
      color: #1890ff;
    }

    .btn:last-child {
      margin-top: 8px;
    }
  }
}
.itemEn {
  height: 90px;
}
.margin_l_r8 {
  margin: 0 8px;
}

.margin_t_4 {
  margin-top: 4px;
}

.margin_r_12 {
  margin-right: 12px;
}
</style>
